.main{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    text-transform: uppercase;
    text-align: center;
}
.mainCheck{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    
}
.mainCheckDiv{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin: 10px;
    color: black;
    background: #eee;
    padding: 15px 5px;
    border-radius: 10px;
    font-size: 15px;
    min-height: 100px;
}
.statusMain{
    margin: 5px;
}
.mainCheckDiv:hover{
    background: #b2b2b2;
}
.mainCheckDiv h3{
    margin-top: 0;
}
.checkDate{
    text-align: right;
    font-size: 12px;
}
.green{
    color: green
}
.sumMainH2{
    margin-left: 10px;
    color: #f90;
}
.sumMainH2:hover{
    cursor: pointer;
}
.mt30{
    margin-top: 30px;
}
.bold{
    font-weight: bold;
}
.ahref{
    color: #f90;
}
.ahref:hover{
    color: green;
}
.modal{
    text-align: center;
}
.modal input{
    width: 90%;
    margin: 0 0 10px 0;
    border: 1px solid #d2d2d2;
    padding: 5px;
}
.modal button{
    margin: 0 5px;
    padding: 10px;
    border: 0;
    color: #fff;
    cursor: pointer;
}
.modal button:hover{
    opacity: 0.7;
}
.greenBg{
    background: green;
}
.redBg{
    background: red;
}
.mainKp{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    text-transform: uppercase;
    /* text-align: center; */
}
.mainKp div{
    padding: 5px;
}

