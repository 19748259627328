.main{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    text-align: center;
}
.twoBlock{
    display: grid;
    grid-template-columns: 2fr 1fr;
    text-align: center;
}
.main div{
    border: 1px solid #eee;
    padding: 10px;
}
.main div span{
    margin-left: 10px;
}
.active{
    background: #d2d2d2 !important;
}
.filter{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    text-align: center;
    margin-bottom: 10px;
}
.filter div{
    background: #eee;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}
.filter div:hover{
    background: #d2d2d2;
}
