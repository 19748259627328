.mainTwoBlocks{
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-bottom: 0 !important;
    border-left: 0 !important;
    font-size: 19px;
}
.grid_9_3{
    display: grid;
    grid-template-columns: 9fr 3fr;
}
.grid_9_4{
    display: grid;
    grid-template-columns: 9fr 4fr;
}

.mainThree{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.mainThree{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.mainThreeBlocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: 0 !important;
    border-left: 0 !important;
    font-size: 19px;
}
.mainThreeBlocks div{
    text-align: center;
    padding: 10px 10px;
    border-bottom: 1px solid rgb(189, 189, 189);
    border-left: 1px solid #d2d2d2;
    box-shadow: 1px 1px 1px #d2d2d2;
}
.mainTwoBlocks div{
    text-align: center;
    padding: 10px 10px;
    border-bottom: 1px solid rgb(189, 189, 189);
    border-left: 1px solid #d2d2d2;
    box-shadow: 1px 1px 1px #d2d2d2;
}


.infoBlock, .infoBlock div{
    padding: 10px;
    font-size: 20px;
}
.infoBlock svg{
    margin: 0 10px;
    color: #44912c;
}
.infoBlock span{
    /* text-decoration: underline; */
    border-bottom: 1px solid #44912c;
}
.center{
    text-align: center;
}
.bold{
    font-weight: bold;
}
.pensil, .print{
    font-size: 13px;
    color: green;
    position: relative;
    bottom: 10px;
    cursor: pointer;
    border-bottom: 0 !important;
}
.pensil:hover{
    color: #f90;
}
.inputModal{
    padding: 10px;
    width: 90%;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    margin-bottom: 10px;
}
.twoBlocksModal{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.button{
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
    opacity: 0.7;
}
.button:hover{
    opacity: 1;
}
.red{
    color:red;
}
.comment textarea{
    width: 95%;
    min-width: 400px;
    min-height: 200px;
    text-align: left;
}
.showKp{
    padding: 20px;
    color: #fff;
    cursor: pointer;
    opacity: 0.5;
}
.showKp:hover{
    opacity: 0.7;
}
.bgGreen{
    background: #00ba09;
}
.bgRed{
    background: red;
}
.bgYellow{
    background: #ff9900;
}
.active{
    opacity: 1;
}
.commentText{
    padding: 20px !important;
    background: #eee;
}
.settings{
    text-align: right;
    margin-right: 10px;
    font-size: 1.2rem;
    cursor: pointer;
}
.settings span{
    background: red;
    padding: 5px 10px;
    color: #fff;
}
.repair{
        padding: 10px;
        background: #eee;
        width: 200px;
        text-align: center;
        cursor: pointer;
        border: 0;
}
.settings span:hover{background:rgb(128, 19, 19)}