.nomBlock{
    display: grid;
    grid-template-columns: 50px 1fr;
    padding: 5px;
    margin: 5px;
    border: 1px solid #eee;
    background: #eee;
}
.filterx{
    display: flex;
}
.filterxDiv{
    background: #eee;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
}
.nomBlock div{
    padding: 2px;
}
.search{
    display: grid;
    grid-template-columns: 100px repeat(4,1fr) 50px 50px;
    padding: 5px;
    margin-bottom: 5px;
}
.refresh{
    margin-left: 8px;
    color: red;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
}
.refresh:hover{
    color: black;
}
.twoBlocks{
    display: grid;
    grid-template-columns: 75px 1fr;
    text-align: left;
}
.twoBlocksFilter{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
}
.addChannelFilter{
    display: inline-block; /* Превращаем блочный элемент в строчный */
    text-align: right; /* Выравнивание по правому краю */
    padding: 10px;
    background: #00ba09;
    color: #fff;
    font-weight: bold;
}
.addChannelFilter:hover{
    cursor: pointer;
    opacity: 0.7;
}
.addChannelFilter * {
    box-sizing: border-box; /* Учитываем padding и border в расчете ширины элементов */
  }