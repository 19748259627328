.main{
    display: grid;
    grid-template-columns: 100px 2fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #d2d2d2;
    text-align: center;
}
.main select{
    width: 90%;
    padding: 3px;
    border: 1px solid #d2d2d2;
}
.saveButton{
    width: 150px;;
    padding: 10px;
    background: #d2d2d2;
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    display: block;
}
.saveButton:disabled{
    background: #eee;
    cursor: initial;
    display: none;
}
.otherProducts span{
    color: #00ba09;
    cursor: pointer;
    background: #eee;
    padding: 5px 10px;
    margin: 2px;
}
.otherProducts span:hover{
    background: #d2d2d2;
}
.workerSpan{
    margin: 1px;
    cursor: pointer;
    background-color: #eee;
    padding: 2px 5px;
}
.workerSpan:hover{
    background: #d2d2d2;
}
.workersMain{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.redactionColl{
    font-size: 12px;
    position: relative;
    bottom: 10px; left: 10px;
    cursor: pointer;
}
.red{
    color: red;
}
.comment{
    padding: 2px 7px;
    background: #00ba09;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
}