.main, .mainSubentries, .mainSubentries2{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    cursor: pointer;
}
.main:hover, .mainSubentries:hover, .mainSubentries2:hover{
    background-color: #eee;
}
.main div, .mainSubentries2 div, .mainSubentries div{
    padding: 10px;
    border-bottom: 1px solid #eee;
}
.mainSubentries{
    margin: 10px 0;
    margin-left: 50px;
    font-size: 15px;
}
.mainSubentries2{
    margin: 10px 0;
    margin-left: 100px;
    font-size: 13px;
}
.mainAdd{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
}
.subentriesLine{
    border-left: 1px solid #eee;
}
.font{
    font-weight: bold;
}
.center{
    text-align: center;
}
.mainCash input, select, textarea{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
}
.mainCash select{
    width: 90%;
}
.button{
    height: 100%;
}
.mainCash textarea{
    text-align: left;
}
.button:hover{
    background-color: #d2d2d2;
    cursor: pointer;
}
.title{
    cursor: pointer;
    border-bottom: 1px solid #ff9900;
    margin-right: 10px;
}
.title:hover{
    color: #ff9900;
}
.m100{
    margin-left: 100px;
}
