.table{
    display: grid;
    /* grid-template-rows: repeat(6,1fr); */
    grid-template-columns: repeat(6,1fr);
    grid-gap: 1vw;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}
.table:hover{
    background: #eee;
}
.header{
    font-size: 18px;
    font-weight: bold;
}
.collInput{
    width: 100px;
    border: 2px solid #d2d2d2;
    margin-right: 5px;
}
.saveButton{
    width: 150px;;
    padding: 10px;
    background: #d2d2d2;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    display: block;
}
.saveButton:disabled{
    background: #eee;
    cursor: initial;
    display: none;
}
.green{
    color: green;
}
.orange{
    color: #ff9900;
    text-decoration: underline;
}
.pencil{
    margin: 0 10px;
    cursor: pointer;
    vertical-align: super;
    font-size: 12px;
}
.pencil:hover{
    color: green;
}
.red{
    background: #d35a5a;
}