.main{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.main div{
    padding: 10px;
}
.main:hover{
    background: #eee;
}
.weight{
    font-weight: bold;
    font-size: 18px;
}
.green{
    color: green;
}
.red{
    color: red;
}
.border{
    border: 1px solid #eee;
}
input{
    cursor: pointer;
}