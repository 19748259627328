.main{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.main div{
    background: #eee;
    border: 0;
    cursor: pointer;
    color: #000;
    margin: 2px;
    padding: 10px;
    text-align: center;
    font-size: 17px;
}
.main div:hover{
    background: #d2d2d2;
}