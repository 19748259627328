.mainDiv{
    z-index: 99;
}
.none{
    display: none;
}
.show{
    display: block;
}
.button{
    position: fixed;
    top: 10%;
    right: 0;
    background: #f90;
    border-radius: 5px 0 0 5px;
    padding: 10px 10px;
    color: white;
    text-shadow: 1px 1px 1px black;
}
.button:hover{
    cursor: pointer;
    background: #d2d2d2;
}
.main{
    position: fixed;
    top: 10%;
    right: 0;
    background: #f90;
    border-radius: 5px 0 0 5px;
    padding: 10px 10px;
    
}
.main div{
    color: white;
    text-shadow: 1px 1px 1px black;
    margin: 5px 0;
    cursor: pointer;
} 
.close{
    position:absolute;
    right: 5px;
    top: -3px;
    color: red !important;
    text-shadow: none !important;
}