.main{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.main:hover{
    background-color: #eee;
}
.main div{
    padding: 10px;
    border-bottom: 1px solid #eee;
}
.mainAdd{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
}
.font{
    font-weight: bold;
}
.center{
    text-align: center;
}
.mainCash input, select, textarea{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
}
.mainCash select{
    width: 90%;
}
.button{
    height: 100%;
}
.mainCash textarea{
    text-align: left;
}
.button:hover{
    background-color: #d2d2d2;
    cursor: pointer;
}
.title{
    cursor: pointer;
    border-bottom: 1px solid #ff9900;
}
.title:hover{
    color: #ff9900;
}
text tspan{
    font-size: 16px;
    font-weight: bold;
}
h3 a{
    color: black;
}
h3 a:hover{
    color: #ff9900;
}