.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    overflow-y: auto;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
    z-index: 100;
}

.modal.big {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    z-index: 100;
}

.modal__content {
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    transform: scale(0.5); /* Use transform for scaling */
    transition: 0.4s all;
    overflow: auto;
    max-height: 600px;
}

.modal__content.active {
    transform: scale(1); /* Use transform for scaling */
}

.close {
    text-align: right;
}

.close span {
    color: red;
    cursor: pointer;
    background: #eee;
    padding: 3px 5px;
}

.close span:hover {
    color: rgb(212, 41, 41);
    background: #d2d2d2;
}

@media (max-width: 980px) {
    .modal__content.active {
        transform: scale(0.7); /* Use transform for scaling */
    }
}
