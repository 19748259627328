.main{
    display: grid;
    grid-template-columns: 75px 5fr repeat(5,1fr);
    grid-column-gap: 10px;
    background: #eee;
}
.namePlusPlanner{
    display: grid;
    grid-template-columns: 2fr 100px;
    border: 0;
}
.namePlusPlanner div{
    border: 0 !important;
}
.main:hover{
    background: #d2d2d2;
}
.main div{
    border-bottom: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    padding: 7px;
}
.countDate{
    padding: 0;
    margin: 0;
    font-size: 13px;
    color: green;
}
.dostup{
    margin: 5px;
}
.dostup span{ 
    background: #eee;
    margin: 5px;
    padding: 10px 20px;
}
.dostup span:hover{ 
    background: #d2d2d2;
    cursor: pointer;
}
.dostup a{
    color: #00ba09;
}
.dostup .active{
    background: #d2d2d2 !important;
}
.sort:hover{
    color: #00ba09;
    cursor: pointer;
}