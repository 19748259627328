.header{
    display: grid;
    grid-template-columns: 3fr 1fr;
    /* border: 1px solid #eee; */
}
.header button{
    padding: 13px 0;
    background: #d2d2d2;
    border: 0;
    cursor: pointer;
    color: #fff;
}
.header button:hover{
    background: #888;
}
.input{
    width: 60%;
    padding: 10px;
    font-size: 18px;
    margin-top: 20px;
}
.filtr{
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.filtr_header{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.filtr_header div{
    background: #d2d2d2;
    border: 0;
    cursor: pointer;
    color: #000;
    margin: 2px;
    padding: 10px 10px;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
}
.searchButton{
    width: 30%;
    padding: 10px;
    font-size: 18px;
    margin-top: 20px;
}
.inStock{
    text-align: right;
    margin-right: 10px;
    color: #00ba09;
    font-size: 20px;
}