.main{
    display: grid;
    grid-template-columns: 50px repeat(4,1fr);
}
.main div{
    border: 1px solid #eee;
    padding: 5px;
    cursor: pointer;
}
.main:hover{
    background: #d2d2d2;
}
.main a{
    color: black;
    text-decoration: underline;
}
.weight{
    font-weight: bold;
}
.center{
    text-align: center;
}