.main{
    display: grid;
    grid-template-columns: 70px repeat(2, 2fr) 1fr 4fr 4fr 1fr;
    border-bottom: 0 !important;
    border-left: 0 !important;
    font-size: 19px;
}
.main div{
    text-align: center;
    padding: 10px 10px;
    border-bottom: 1px solid rgb(189, 189, 189);
    border-left: 1px solid #d2d2d2;
    box-shadow: 1px 1px 1px #d2d2d2;
}
.miniBlocks{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.miniBlocks div{
    height: 20px;
}
.miniBlocks div:hover, .iconHover div:hover{
    cursor: pointer;
    background: #d2d2d2;
}
.miniBlocks div:hover svg, .iconHover div:hover svg{
    color: #44912c;
}
.pensil{
    font-size: 13px;
    color: green;
    position: relative;
    bottom: 10px;
    left: 5px;
    cursor: pointer;
    border-bottom: 0!important;
}
.countModuls{
    padding: 2px 5px;
    color: #fff;
    background: red;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}
.twoBlocks{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}
.twoBlocks div{
    padding: 15px;
    margin: 5px;
    background: #eee;
    cursor: pointer;
}
.twoBlocks div:hover{
    opacity: 0.5;
}
[data-tooltip] {
    position: relative; /* Относительное позиционирование */ 
}
[data-tooltip]::after {
    content: attr(data-tooltip); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    width: 100px; /* Ширина подсказки */
    left: 0; top: 0; /* Положение подсказки */
    background: #f90; /* Синий цвет фона */
    color: #fff; /* Цвет текста */
    padding: 0.5em; /* Поля вокруг текста */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
    pointer-events: none; /* Подсказка */
    opacity: 0; /* Подсказка невидима */
    transition: 1s; /* Время появления подсказки */
    z-index: 90;
    text-align: center;
} 
[data-tooltip]:hover::after {
    opacity: 1; /* Показываем подсказку */
    top: 2.2em; /* Положение подсказки */
}
.textarea{
    border-bottom: 0!important;
    width: 95%;
    text-align: left;
    padding: 5px;
    min-height: 75px;
}
.personalRepairsList{
    display: grid;
    grid-template-columns: 4fr 1fr;
    border: 0 !important;
    box-shadow: none !important;
}
.personalRepairsList div{
    border: 0 !important;
}
.button{
    text-align: center;
    margin-top: 20px;
}
.button button{
    background: #36ab3c;
    color: #fff;
    padding: 15px;
    border: 0;
    cursor: pointer;
}
.button button:hover{
    opacity: 0.7;
}
.archive{
    opacity: 0.5;
}
.archive:hover{
    opacity: 1;
}
.w100{
    width: 100%;
}
.center{
    text-align: center;
}
.fio{
    text-align: left !important;
    border: 0 !important;
    font-size: 14px !important;
    padding: 0;
    margin: 0;
    color: #000;
}
.m10{
    margin-right: 5px;
}