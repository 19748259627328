.main{
    display: grid;
    grid-template-columns: repeat(6,1fr);
}
.main:hover{
    background-color: #eee;
}
.main div{
    padding: 10px;
    border-bottom: 1px solid #eee;
}
.bold{
    font-weight: bold;
}
.green{
    color: green;
}
.red{
    color: red;
}
.div3{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    background: #eee;
    box-shadow: 3px 3px 3px #888;
}