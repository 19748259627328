.main{
    display: grid;
    grid-template-columns: 1fr;
}
.main div{
    background: #00ba09;
    border: 0;
    cursor: pointer;
    color: #fff;
    margin: 2px;
    padding: 10px 10px;
    text-align: center;
    font-size: 17px;
    opacity: 0.7;
}
.active{
    opacity: 1 !important;
    background: #ff9900 !important;
}
.main div:hover{
    background: #ff9900;
}