.main{
  display: grid;
  grid-template-columns: repeat(12,1fr);
}
.main div{
  padding: 10px;
  background: #eee;
  margin: 0 5px;
  text-align: center;
}
.main div:hover{
  background: #d2d2d2;
  cursor: pointer;
}
.main a{
  color: #000;
}
.active{
  background: #d2d2d2 !important;
}