.main{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.twoBlocksGrid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.main div{
    border-radius: 30px;
    padding: 20px;
    margin: 10px;
    color: #fff;
    font-size: 16px;
}
.main div:hover{
    opacity: 0.8;
    cursor: pointer;
}
.main a, .mainCheck a, a{
    text-decoration: none;
}

.mainCheck{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.mainCheckDiv{
    margin: 10px;
    color: black;
    background: #eee;
    padding: 15px 5px;
    border-radius: 10px;
    min-height: 130px;
}
.mainCheckDiv:hover{
    background: #b2b2b2;
}
.mainCheckDiv h3{
    margin-top: 0;
}
.checkDate{
    text-align: right;
    font-size: 12px;
}
.checkDate svg{
    margin-right: 5px;
}
.back{
    color: blue;
    text-transform: uppercase;
    font-size: 16px;
}
.back:hover{
    color: green;
}
.red{
    color: red;
}
.green{
    color: green
}
.redBack{
    background: red !important;
}
.greenBack{
    background: green !important;
}
.greenBack2{
    background: rgb(23, 212, 23) !important;
}
.blueBack{
    background: blue !important;
}
.yellowBack{
    background: #f90 !important;
}
.blackBack{
    background: black !important;
}
.blue2Back{
    background: rgb(15, 106, 226) !important;
}
.blueMarketing{
    background: #4f81bd;
}
.title{
    margin-left: 20px;
    font-size: 30px;
    border-bottom: 1px solid #4f81bd;
}
.left{
    text-align: left;
    padding-left: 5px;
    
}
.left a{
    color: #000;
}
.left a:hover{
    color: #f90
}
.number{
    position: relative;
    bottom: 15px;
    left: 10px;
    background: #f70000;
    padding: 2px 7px;
    font-weight: bold;
    border-radius: 12px;
}