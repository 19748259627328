.main{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-column-gap: 10px;
    background: #eee;
}
.link{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 10px;
    font-size: 17px;
    font-weight: bold;
}
.link div{
    padding: 10px;
    margin: 5px;
    /* background: #eee; */
    text-align: center;
    text-decoration: underline;
}
.main:hover{
    background: #d2d2d2;
}
.main a{
    color: #1900ff;
    text-decoration: none;
    text-transform: uppercase;
}
.main a:hover{
    color: #00ba09;
}
.main div{
    border-bottom: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    padding: 10px;
}
.right{
    text-align: right;
}
.header{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}
.searchAndPeriod{
    display: grid;
    grid-template-columns: 5fr 2fr;
    margin-bottom: 10px;
}
.period{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.period span{
    margin: 0 5px;
}
.search, .period input, .period button{
    width: 95%;
    padding: 10px;
    border: 1px solid #d2d2d2;
    font-size: 17px;
}
.period input, .period button{
    width: 85% !important;
    cursor: pointer;
}
.period button:hover{
    background: #d2d2d2;
}
.dostup{
    margin: 5px;
}
.dostup span{ 
    background: #eee;
    margin: 5px;
    padding: 10px 20px;
}
.dostup span:hover{ 
    background: #d2d2d2;
    cursor: pointer;
}
.dostup a{
    color: #00ba09;
}
.dostup .active{
    background: #d2d2d2;
}
.statistics{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-column-gap: 10px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
.circle{
    width: 100px; /* поддерживаются любые единицы, в том числе проценты */
    margin: 1em auto;
    background: silver;
    border-radius: 50%;
    opacity: 0.2;
    display: inline-block;
}
.circle_min{
    width: 50px; /* поддерживаются любые единицы, в том числе проценты */
    margin: 0.5em 0;
    background: silver;
    border-radius: 50%;
    opacity: 0.2;
    display: inline-block;
}
.circle::before, .circle_min::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
}
.statistics svg{
    margin-left: 5px;
    opacity: 1 !important;
}
.statistics a:hover{
    color: #00ba09;
}
.green, .green a{
    color: rgb(0, 196, 159);
    opacity: 0.2;
}
.purple, .purple a{
    color: rgb(255, 111, 97);
    opacity: 0.2;
}
.blue, .blue a{
    color: rgb(0, 136, 254);
    opacity: 0.2;
}
.blueTest, .blueTest a{
    color: rgb(0, 224, 254);
    opacity: 0.2;
}
.orange{
    color: rgb(255, 187, 40);
    opacity: 0.2;
}
.red, .red a{
    color: rgb(255, 128, 66);
    opacity: 0.7;
}
.greenBg{
    background: rgb(0, 196, 159);
}
.purpleBg{
    background: rgb(255, 111, 97);
}
.greenDopBg{
    background: rgb(7, 196, 0);
}
.blueBg{
    background: rgb(0, 136, 254);
}
.blueBgTest{
    background: rgb(0, 224, 254);
}
.orangeBg{
    background: rgb(255, 187, 40);
}
.redBg{
    background: rgb(255, 128, 66);
}
.active, .active a{
    opacity: 0.9;
}
.greenText{
    color: rgb(0, 196, 159);
}
.superGreenText{
    color: green;
}

.orangeText{
    color: orange;
}
.brownText{
    color: saddlebrown;
}


.greenDopText, .greenDopText a{
    color: rgb(7, 196, 0);
    opacity: 0.7;
}
.redText{
    color: rgb(255, 128, 66);
}
.filter span{
    padding: 10px;
    background: #f90;
    color: #fff;
    font-size: 16px;
    margin-left: 20px;
}
.filter span:hover{
    background: #d2d2d2;
    cursor: pointer;
}
.close{
    padding: 0 !important;
    margin: 0 !important;
    vertical-align: super;
    color: red !important;
    cursor: pointer;
    background: none !important;
}
.ttn{
    padding: 5px 0 !important;
}
.divCircle{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 10px;
    text-align: center; 
}
.greenDop{
    background: rgb(4, 224, 70);
}
.redDop{
    background: rgb(224, 30, 4);
}
.purpleDop{
    background: rgb(224, 4, 224);
}
/* @media (max-width: 920px) { 
    .blockFor{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .twoBlocksFilter{
        display: grid;
        grid-template-columns: 1fr;
    }
} */
@media (max-width: 620px) { 
    .link{
        grid-template-columns: repeat(2,1fr);
    }
    .searchAndPeriod{
        grid-template-columns: 1fr;
    }
    .statistics{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-column-gap: 10px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
    }
    .circle{
        width: 50px; /* поддерживаются любые единицы, в том числе проценты */
        margin: 1em auto;
    }
    .main{
        font-size: 0.8rem;
    }
}