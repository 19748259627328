.main{
    display: grid;
    grid-template-columns: 50px repeat(6,1fr);
}
.main:hover{
    background-color: #eee;
}
.main div{
    padding: 10px;
    border-bottom: 1px solid #eee;
}
.bold{
    font-weight: bold;
}
.green{
    color: green;
}
.red{
    color: red;
}
.div3{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    background: #eee;
    box-shadow: 3px 3px 3px #888;
    margin-top: 10px;
}
.filter{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.inputMain{
    display: grid;
    grid-template-columns: 50px 1fr;
}
.inputMain div{margin: 10px;}
.p10{padding: 10px;}
.inputMain input{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    text-align: left;
}
.filter div{
    margin: 10px;
}
.filter button{
    background: red;
    color: #fff;
    border: 0;
    padding: 5px 10px;
    box-shadow: 1px 1px 1px #eee;
    font-weight: bold;
    
}
.filter button:hover, .button button:hover{
    background: #d2d2d2;
    color: #fff;
    cursor: pointer;
}
.button, .center{text-align: center;}
.button button{
    background: #d2d2d2;
    border: 0;
    color: #000;
    font-weight: bold;
    padding: 10px;
}
.svg{
    text-align: right;
    margin: 10px;
    font-size: 1.4rem;
}