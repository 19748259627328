.main{
    display: grid;
    grid-template-columns: 1fr 6fr;
    text-align: center;
    background: #eee;   
}
.main div{
    text-align: center;
    padding: 10px 10px 0px;
}
.bold{
    font-weight: bold;
}
.mainModuls{
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-column-gap: 5px;
}
.mainModuls div{
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
}
.main_stage{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    padding: 0 !important;
}
.main_stage div{
    padding: 5px !important;
}
.main_stage img{
    width: 75px;
}
.inputDate{
    width: 90%;
    border: 1px solid #eee;
    padding: 10px 5px;
}
.mainSection{
    margin-top: 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #d2d2d2;
    box-shadow: 2px 2px 2px #888;
}
.dateOp{
    font-size: 12px;
}
.fs10{
    font-size: 10px;
}
.main2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 !important;
    margin: 0;
}
.main2 div{
    font-size: 10px !important;
}
.main2 div:hover{
    background: #d2d2d2;
    color: #888;
    cursor: pointer;
}
.twoBlocks{
    display: grid;
    grid-template-columns: 4fr 2fr;
}
.threeBlocks{
    display: grid;
    grid-template-columns: 7fr 1fr 1fr;
}
.blue{
    color: #005bbc;
}
.ttn{
    text-align: center;
}
.ttn input{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    width: 80%;
}
.ttn button{
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
    margin-top: 10px;
}
.red{
    color: red;
}
.buttonTTN{
    border: 1px solid #e3e3e3;
    padding: 5px 10px;
    background: #d2d2d2;
    color: #666;
    font-weight: bold;
    cursor: pointer;
}
.buttonTTN:hover{
    background: #00ba09;
    color: #fff;
}