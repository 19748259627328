.main{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 10px;
    /* grid-row-gap: 10px; */
    margin-top: 5px;
    text-align: center;
    /* background: #eee; */
}
.main div{
    background: #eee;
    padding: 5px;
}
.header{
    font-size: 18px;
    font-weight: bold;
}
.mainYear{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-column-gap: 10px;
}
.mainYear div{
    text-transform: uppercase;
    color: #00ba09;
    padding: 5px;
    background: #eee;
    text-align: center;
    cursor: pointer;
}
.year{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 10px;
    margin: 15px;
}
.year div{
    background: #eee;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}
.year div:hover{
    background: #d2d2d2;
}