.m20{
    margin-top: 20px;
}
.fwb{
    font-weight: bold;
}
.main{
    display: grid;
    grid-template-columns: 50px 1fr 2fr 1fr 1fr;
    grid-column-gap: 10px;
    background: #eee;
}
.main:hover{
    background: #d2d2d2;
}
.main a{
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
}
.main a:hover{
    color: #00ba09;
}
.main div{
    border-bottom: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    padding: 15px;
}
.add span{
    padding: 10px 20px;
    background: #00ba09;
    color: #fff;
    cursor: pointer;
}
.add span:hover{
    opacity: 0.7;
}
.remove, .change{
    cursor: pointer;
    font-weight: bold;
    margin: 0 10px;
    font-size: 1.2rem;
}
.remove{
    color: red;
}
.change{
    color: #888;
}
.remove:hover, .change:hover{
    opacity: 0.7;
}