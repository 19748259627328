.addNewSupplier{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10px;
    background: #eee;
    padding: 10px 5px;
}
.addNewSupplier input, .addNewSupplier button{
    width: 80%;
    padding: 2px;
    border: 2px solid #d2d2d2;
}
.addNewSupplier:hover{
    background: #d2d2d2;
}
.addNewSupplier button:hover{
    cursor: pointer;
}
.center{
    text-align: center;
}
.button{
    border: 1px solid #d2d2d2;
    padding: 5px 10px;
    margin: 10px;
    background: #eee;
}
.button:hover{
    border: 2px solid #eee;
    cursor: pointer;
    
}
