.main, .hei{
    display: grid;
    grid-template-columns: 50px 1fr 1fr 3fr 3fr 50px;
    background: #eee;
}
.main div{
    padding: 10px 10px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
}
.hei{
    margin-top: 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #d2d2d2;
    box-shadow: 2px 2px 2px #888;
    text-align: center;
    padding: 10px;
}
.collChannel{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.main svg{
    color: #d2d2d2;
}
.main svg:hover{
    color: #000;
    cursor: pointer;
}