.dostup{
    margin: 5px;
}
.dostup span{ 
    background: #eee;
    margin: 5px;
    padding: 10px 20px;
}
.dostup span:hover{ 
    background: #d2d2d2;
    cursor: pointer;
}
.dostup a{
    color: #00ba09;
}
.dostup .active{
    background: #d2d2d2 !important;
}
.mainKp{
    display: grid;
    grid-template-columns: 50px 2fr 2fr 1fr 3fr 1fr;
}
.mainKp div{
    padding: 5px;
}