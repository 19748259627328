.main{
    display: grid;
    grid-template-columns: 30px repeat(5,1fr);
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center;
}
.main:hover{
    background: #eee;
}
.mainThreeHeader{
    font-weight: bold;
}
.inputColl{
    margin: 10px;
}
.inputColl input, .inputColl button{
    padding: 10px;
    text-align: center;
    border: 1px solid #d2d2d2;
    font-weight: bold;
    margin-bottom: 20px;
}
.inputColl button{
    cursor: pointer;
}
.description{
    text-align: left;
    margin: 20px 0;
}
.name{
    margin: 20px 0;
    font-size: 24px;
}