.main{
  margin-bottom: 10px;
}
.main button{
  background: #eee;
  border: 1px solid #eee;
  padding: 10px;
  margin: 1px;
  cursor: pointer;
}
.main button:hover{
  background: #d2d2d2;
}
h3{
  margin: 10px;
}
.active{
  background: #d2d2d2 !important;
}