.layout {
    margin-top: 20px;
}

.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}

.react-grid-item.cssTransforms {
    transition-property: transform;
}

.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    z-index: 3;
}

.react-grid-item.dropping {
    visibility: hidden;
}

.react-grid-placeholder {
    background: #ccc;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-transition: height 200ms, width 200ms;
    transition: height 200ms, width 200ms;
}

.react-grid-dragHandle {
    cursor: move;
}

.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid-container {
    width: 100%;
    max-width: 1200px;
}

.control-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
}
