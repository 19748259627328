.mainCheck, .mainCheckDiv{
    display: grid;
    grid-template-columns: 50px 3fr 2fr 7fr 1fr 2fr 1fr 1fr;
}
.mainCheck{
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    
}
.twoBlocksWithH1{
    display: grid;
    grid-template-columns: 1fr 10fr;
}
.course{
    position: absolute;
    right: 0;
    top: 5%;
    padding: 10px;
    margin-right: 5px;
}

.blueBg{
    background: #00f;
    color: #fff;
}
.yellowBg{
    background: yellow;
    color: #000;
}
.print p{
    margin: 10px;
}
.mainCheckDivPrint, .mainCheckPrint{
    display: grid;
    grid-template-columns: 50px 3fr 7fr repeat(4,1fr);
    text-align: center;
}
.mainCheckDivPrint div{
    border: 1px solid #d2d2d2;
    padding: 10px;
}
.mainCheckDiv{
    color: black;
    border: 1px solid #d2d2d2;
    padding: 15px 5px;
    font-size: 15px;
    min-height: 100px;
}
.mainCheckDiv h3{
    margin-top: 0;
}
.ahref{
    color: #00ba09;
    padding: 20px;
    cursor: pointer;
    font-size: 18px;
}
.data{
    display: grid;
    grid-template-columns: 2fr repeat(4,1fr);
}
.selectPlus{
    display: grid;
    grid-template-columns: 11fr 1fr;
}
.h2Header{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin-bottom: 20px;
}
.texth3{
    text-align: center;
}
.green{
    background: #00ba09;
    color: #fff;
}
.input, .textarea, .select{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    width: 90%;
    text-align: left;
}
.select{
    width: 97%;
}
.priceCurrency{
    display: grid;
    grid-template-columns: 4fr 1fr;
}
.currency div{
    border: 1px solid #d2d2d2;
    margin: 2px 0;
    font-weight: bold;
    cursor: pointer;
    padding: 3px 0;
}
.inputNumber{
    width: 100px;
}
.iconDelete{
    color: red;
    font-size: 20px;
    padding: 5px;
}
.divWithSignature{
    display: grid;
    grid-template-columns: 1fr 300px 1fr;
    padding: 100px 0;
}
.signature{
    max-width: 300px;
    position: relative;
    bottom: 100px; 
}

[data-tooltip] {
    position: relative; /* Относительное позиционирование */ 
}
[data-tooltip]::after {
    content: attr(data-tooltip); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    width: 100px; /* Ширина подсказки */
    left: 0; top: 0; /* Положение подсказки */
    background: #f90; /* Синий цвет фона */
    color: #fff; /* Цвет текста */
    padding: 0.5em; /* Поля вокруг текста */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
    pointer-events: none; /* Подсказка */
    opacity: 0; /* Подсказка невидима */
    transition: 1s; /* Время появления подсказки */
    z-index: 90;
    text-align: center;
} 
[data-tooltip]:hover::after {
    opacity: 1; /* Показываем подсказку */
    top: 2.2em; /* Положение подсказки */
}
