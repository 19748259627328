.main{
  margin-bottom: 10px;
}
.main button{
  background: #eee;
  border: 1px solid #eee;
  padding: 10px;
  margin: 1px;
  cursor: pointer;
}
.activeWorker{
  background: #d2d2d2 !important;
}
h3{
  margin: 10px;
}
.mainTable{
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 20px;
  background: #eee;
  padding: 10px;
  border: 1px solid #d2d2d2;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.table{
    display: grid;
    grid-template-columns: 100px 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #d2d2d2;
    text-align: center;
}