.mainDiv{
    z-index: 99;
}
.none{
    display: none;
}
.show{
    display: block;
}
.button{
    position: fixed;
    top: 30%;
    right: 0;
    background: #f90;
    border-radius: 5px 0 0 5px;
    padding: 10px 10px;
    color: white;
    text-shadow: 1px 1px 1px black;
}
.button:hover{
    cursor: pointer;
    background: #d2d2d2;
}
.main{
    position: fixed;
    top: 27%;
    right: 0;
    background: #f90;
    border-radius: 5px 0 0 5px;
    padding: 10px 10px;
    
}
.main a div{
    color: white !important;
    text-shadow: 1px 1px 1px black;
    margin: 5px 0;
} 
.main a{
    color: #d2d2d2;
}
.close{
    position:absolute;
    right: 3px;
    top: 3px;
    color: red;
    cursor: pointer;
}