.main{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 10px;
}
.main div{
    padding: 5px;
}
.weight{
    font-weight: bold;
    font-size: 18px;
}
.mainArticles{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    text-align: left;
}
.mainArticles div{
    background: #eee;
    margin: 5px;
    cursor: pointer;
}
.mainArticles div:hover{
    background: #d2d2d2;
}
.active{
    background: #8b8989 !important;
}
.main input, .main button{
    border: 1px solid #eee;
    padding: 10px;
}
.main button:hover{
    background: #d2d2d2;
    cursor: pointer;
}
.title{
    color: #f90;
}
.center{
    text-align: center;
}