.distribution svg{
    color: #00ba09;
    cursor: pointer;
}
.distribution svg{
    color: #36ab3c;
    cursor: pointer;
}
.main{
    display: grid;
    grid-template-columns: 70px 1fr 3fr 1fr 3fr 1fr;
    grid-column-gap: 10px;
    background: #d2d2d2;
    font-size: 18px;
}
.mainFor{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 10px;
    background: #d2d2d2;
    font-size: 18px;
    text-align: center;
}

.main div{
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    padding: 7px;
}
.modulsList div{
    border-bottom: 1px solid #eee !important;
    border-right: 1px solid #eee !important;
    padding: 7px !important;
}
.twoBlock{
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 5px;
}
.twoBlock div{
    /* margin: 5px 0; */
    padding: 5px;
}
.textarea{
    width: 95%;
    min-width: 200px;
    min-height: 100px;
    text-align: left;
}
.addDesc input, .addDesc textarea, .addDesc select{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    width: 95%;
}
.addDesc button{
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
}
.addDesc button:hover{
    background-color: #36ab3c;
}
.mainTwo{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    background: #eee;
    padding-left: 15px;
    text-align: center;
}
.mainTwo div{
    border-bottom: 1px solid #eee !important;
    border-right: 1px solid #eee !important;
    padding: 5px !important;
}
.mainTwo a{
    color: hsl(251, 100%, 36%);
    text-decoration: none;
    text-transform: uppercase;
}
.mainTwo:hover{
    background: #d2d2d2;
}
.mainTwo a:hover{
    color: #00ba09;
}
.green{
    background: rgb(0, 196, 159);
    color: #fff;
    font-weight: bold;
}
.dostup{
    margin: 20px;
}
.dostup span{ 
    background: #eee;
    margin: 5px;
    padding: 10px 20px;
}
.dostup span:hover{ 
    background: #d2d2d2;
    cursor: pointer;
}
.dostup a{
    color: #00ba09;
}
.dostup .active{
    background: #d2d2d2;
}
.plusmin, .plusminChange{
    font-size: 12px;
}
.plusminChange{
    opacity: 0 !important;
}
.numberBlock:hover .plusminChange{
    opacity: 1 !important;
}
.actionsDistribution svg{
    margin: 0 10px;
    opacity: 0.7;
}