.main{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 3fr 1fr 1fr;
    grid-column-gap: 10px;
    background: #eee;
}
.main:hover{
    background: #d2d2d2;
}
.main a{
    color: #0c28b3;
    text-decoration: none;
    text-transform: uppercase;
}
.main a:hover{
    color: #00ba09;
}
.main div{
    border-bottom: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    padding: 7px;
}
.modulsList div{
    border-bottom: 1px solid #eee !important;
    border-right: 1px solid #eee !important;
    padding: 7px !important;
}
.bold{
    font-weight: bold;
}
.center{
    text-align: center;
}
.mainFilter{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    text-align: center;
}
.mainFilter select{
    padding: 5px;
    border: 1px solid #eee;
}
.mainSupplier{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    text-align: center;
}
.mainSupplier div{
    padding: 10px;
    margin: 5px;
    background: #eee;
    font-size: 14px;
}
.mainSupplier div:hover{
    background: #d2d2d2;
    cursor: pointer;
}
.active{
    background: #d2d2d2 !important;
}