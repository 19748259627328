.main input{
    border: 1px solid #d2d2d2;
    padding: 5px;
    margin: 5px 2px;
    width: 80%;
}
.main button{
    border: 1px solid #d2d2d2;
    padding: 5px;
    margin: 2px;
    width: 82%;
    cursor: pointer;
}
.main button:hover{
    background: #d2d2d2;
}
