.main{
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: 10px;
    background: #eee;
}
.main:hover{
    background: #d2d2d2;
}
.main a{
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
}
.main a:hover{
    color: #00ba09;
}
.main div{
    border-bottom: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    padding: 7px;
}
.bold{
    font-weight: bold;
}
.center{
    text-align: center;
}
.filtr{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    margin: 10px 0;
}
.filtr div{
    margin: 5px 5px;
    background: #eee;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}
.filtr div:hover, .button:hover{
    background: #d2d2d2;
}
.field__active{
    background: #d2d2d2 !important;
}
.button{
    padding: 10px;
    background: #eee;
    width: 200px;
    text-align: center;
    cursor: pointer;
    border: 0;
}
.filtrBlock{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.filtrBlock div{
    background: #eee;
    border: 1px solid #d2d2d2;
    padding: 10px;
    margin: 2px;
}
.input{
    margin-bottom: 10px;
    padding: 5px 10px;
}
.addNewBlock{
    text-align: center;
}
.icon{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.icon, .icon div{
    border: 0 !important;
    margin: 0;
    padding: 0;
    text-align: right;
}
.icon svg{
    cursor: pointer;
    opacity: 0.5;
    padding: 5px 10px;
}
.icon svg:hover{
    opacity: 1;
    background: #d2d2d2;
    
}
.green{
    color: #00ba09;
}
.red{
    color: red;
}
.filtrWants:hover{
    cursor: pointer;
    background: #d2d2d2;
}