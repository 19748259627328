.newTask{
    text-align: center;
}
.newTask textarea, .addComment textarea{
    width: 95%;
    min-width: 400px;
    min-height: 200px;
    text-align: left;
}
.main{
    display: grid;
    grid-template-columns: 50px 3fr 1fr 2fr 1fr;
    padding: 5px;
    font-size: 17px;
}
.main h4{
    margin: 0;
}
.main div{
    background: #eee;
    padding: 5px;
    border-left: 1px solid #d2d2d2;
}
.dopMain div{
    background: rgb(248 248 248);
    font-size: 16px;
}
.hover:hover{
    background: #d2d2d2 !important;
    cursor: pointer;
}
.main:hover{
    background: #eee;
}
.main button{
    z-index: 50;
}
.twoBlock{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px;
}
.twoBlockMin{
    display: grid;
    grid-template-columns: 3fr 70px;
}
.threeBlock{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 5px;
}
.iconSmall{
    font-size: 12px;
}
.center{
    text-align: center;
}
.hei{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    position: sticky;
	top: 0;
    z-index: 10;
}
.hei div{
    background: #d2d2d2 !important;
}
.hei div, .twoBlock div{
    border: 0;
}
.mt10{
    margin-top: 10px;
}
.mt20{
    margin-top: 20px;
}
.input{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    margin-top: 5px;
}
.taskMain button{
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
}
.taskMain button:hover{
    background-color: #36ab3c;
}
.main svg{
    margin: 0 10px;
    cursor: pointer;
}
.mainComment{
    display: grid;
    grid-template-columns: 50px 3fr 1fr 1fr 1fr;
    padding: 5px;
    border: 1px solid #eee;
    margin-left: 50px;
    margin-bottom: 5px;
}
.showComment{
    margin-left: 20px;
    font-size: 18px;
}
.showComment input{
    transform:scale(1.5);
    opacity:0.9;
    cursor:pointer;
    margin-right: 10px;
}
.archive{
    opacity: 0.5;
}
.archive:hover{
    opacity: 1;
}
.notMainTask{
    padding-left: 50px;
}
[data-tooltip] {
    position: relative; /* Относительное позиционирование */ 
}
[data-tooltip]::after {
    content: attr(data-tooltip); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    width: 70px; /* Ширина подсказки */
    left: 0; top: 0; /* Положение подсказки */
    background: #f90; /* Синий цвет фона */
    color: #fff; /* Цвет текста */
    padding: 0.5em; /* Поля вокруг текста */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
    pointer-events: none; /* Подсказка */
    opacity: 0; /* Подсказка невидима */
    transition: 1s; /* Время появления подсказки */
    z-index: 90;
    text-align: center;
} 
[data-tooltip]:hover::after {
    opacity: 1; /* Показываем подсказку */
    top: 2.2em; /* Положение подсказки */
}
.filter svg{
    margin: 0 10px;
}
.dateReminder{
    font-size: 12px;
    text-align: right;
    position: relative;
    bottom: 10px;
    border: 0 !important;
    color: #00ba09;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-top: 5px;
}
.dateReminder:hover{
    background: #d2d2d2;
    cursor: pointer;
}
.fourBlock{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    border: 0 !important;
}
.fourBlock div{
    border: 0;
}
.w95{
    width: 95% !important;
}