.menu{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}
.menu div{
    padding: 10px;
    background: #eee;
    text-align: center;
}
.menu a{
    text-decoration: none;
    text-transform: uppercase;
    color: #00ba09;
}
.menu a:hover, .menu div:hover{
    background: #d2d2d2;
    color: #00ba09;
}
.menu svg{
    color: #44912c;
    margin: 0 10px;
    font-size: 18px;
}