.main{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.main div{
    border-radius: 30px;
    padding: 20px;
    margin: 10px;
    color: #fff;
    font-size: 16px;
}
.main div:hover{
    opacity: 0.8;
    cursor: pointer;
}
.main a, .mainCheck a, a{
    text-decoration: none;
}
.back{
    color: blue;
    text-transform: uppercase;
    font-size: 16px;
}
.back:hover{
    color: green;
}
.red{
    color: red;
}
.green{
    color: green
}
.redBack{
    background: red !important;
}
.greenBack{
    background: green !important;
}
.greenBack2{
    background: rgb(23, 212, 23) !important;
}
.blueBack{
    background: blue !important;
}
.yellowBack{
    background: #f90 !important;
}
.blackBack{
    background: black !important;
}
.blue2Back{
    background: rgb(15, 106, 226) !important;
}
.blueMarketing{
    background: #4f81bd;
}
.title{
    margin-left: 20px;
    font-size: 30px;
    border-bottom: 1px solid #4f81bd;
}