.center{
    text-align: center !important;
}
.left{
    text-align: left !important;
}
.right{
    text-align: right !important;
}
.red{
    color: #ff9900 !important;
    font-weight: bold !important;
}
.green{
    color: green !important;
    font-style: italic !important;
}
.blue{
    color: blue !important;
    font-style: italic !important;
}
.orange{
    color: orange  !important;
    font-style: italic !important;

}
.yellow{
    color: yellow;
}

.redBg{
    background: red;
}
.greenBg{
    background: #00ba09;
}
.orangeBg{
    background: #ff9900 !important;
}
.greyBg{
    background: grey !important;
}
.redBg, .greenBg, .orangeBg{
    color: #fff;
    font-weight: bold;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}
.notChecked{
    opacity: 0.2;
}
.bold{
    font-weight: bold;
}
.p20{
    padding: 20px;
}
.m20{
    margin: 20px;
}
.fs1rem{
    font-size: 1rem;
}
.mt10{
    margin-top: 10px !important;
}
.mlr10{
    margin: 0 10px !important;
}
.w100{
    width: 100%;
}
.textarea{
    width: 95%;
    min-width: 400px;
    min-height: 200px;
    text-align: left;
    margin: 10px 0;
    padding: 10px;
}
.colspan-grid-6{
    grid-column: span 6;
}
.twoBlocksGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.twoBlocksGridOneOfTwo{
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.twoBlocksGridSixOfOne{
    display: grid;
    grid-template-columns: 6fr 1fr;
}
.input{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin: 10px 0;
    width: 95%;
    text-align: left;
}
.inputDelete{
    background: red;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 15px;
    text-align: center;
    margin-top: 5px;
    border: 0;
    cursor: pointer;
}
.inputAdd{
    background: #00ba09;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 15px;
    text-align: center;
    margin-top: 5px;
    border: 0;
    cursor: pointer;
}
.inputAdd:disabled {
    background: #ccc; /* Change the background color to grey */
    cursor: not-allowed; /* Change the cursor to "not-allowed" */
}
.backInputAdd{
    background: #ff9900;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 15px;
    text-align: center;
    margin-top: 5px;
    border: 0;
    cursor: pointer;
}
.header{
    margin-top: 20px;
    background: #eee;
    border: 1px solid #d2d2d2;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px;
}
.borderB{
    border-bottom: 1px solid #d2d2d2;
}
.icon{
    color: #888;
    margin: 0 5px;
    font-size: 0.8rem;
}
.icon:hover{
    color: #000;
    cursor: pointer;
}
.error{
    color: red;
    text-align: center;
}
