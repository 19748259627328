.main{
    display: grid;
    grid-template-columns: 10% 10% 20% 20% 20% 20%;
    background: #eee;
}
.mainYear{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    margin-bottom: 5px;
}
.mainYear div{
    padding: 10px;
    background: #eee;
    margin: 0 5px;
    text-align: center;
}
.mainYear div:hover{
    background: #d2d2d2;
    cursor: pointer;
}
.mainYear a{
    color: #000;
}

.main div:hover{
    background: #d2d2d2;
    cursor: pointer;
}
.main a{
    color: #000;
}
.active{
    background: #d2d2d2 !important;
}

.namePlusPlanner{
    display: grid;
    grid-template-columns: 2fr 100px;
    border: 0;
}
.namePlusPlanner div{
    border: 0 !important;
}
.main:hover{
    background: #d2d2d2;
}
.main div{
    border-bottom: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    padding: 7px;
}
.dostup{
    margin: 5px;
}
.dostup span{ 
    background: #eee;
    margin: 5px;
    padding: 10px 20px;
}
.dostup span:hover{ 
    background: #d2d2d2;
    cursor: pointer;
}
.link{
    color: green !important;
    font-weight: bold;
}
.dostup a{
    color: #00ba09;
}
.dostup .active{
    background: #d2d2d2 !important;
}
