.mainPageStatistics{
    text-align: center;
}
/* Подменю */
.menu{
    display: grid;
    grid-template-columns: repeat(5,1fr);
}
.menu div{
    margin-top: 20px;
}
.menu a{
    font-size: 16px;
    text-decoration: none;
    color: #fff;
    background: green;
    padding: 5px 10px;
    font-weight: bold;
}
.menu a:hover{
    background: rgb(20, 189, 28);
}