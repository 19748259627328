.main{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    padding: 5px;
    border: 1px solid #eee;
    background: #eee;
}
.main div{
    padding: 5px;
    border: 1px solid #d2d2d2;
}
.plus{
    padding: 2px 5px;
    background: #00ba09;
    color: #fff;
    font-weight: bold;
}
.plus:hover{
    cursor: pointer;
    opacity: 0.7;
}
.active{
    border: 0 !important;
    background: #00ba09 !important;
    color: #fff;
}
.saveButtonActive, .saveButtonNonActive{
    text-align: right;
}
.saveButtonActive button, .saveButtonNonActive button{
    width: 100px;
    background: #00ba09;
    color: #fff;
    border: 0;
    opacity: 0.5;
    padding: 5px;
}
.saveButtonActive button{
    opacity: 1 !important;
}
