.main {
    position: fixed; /* Keep the modal fixed in the viewport */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for true center alignment */
    width: 40%; /* Adjust width as needed */
    z-index: 5;
    background: white; /* Background color */
    padding: 10px; /* Padding for better spacing */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: add box-shadow for a raised effect */
}

.mainButton div:hover {
    cursor: pointer;
}

.mainColl {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr; /* Adjust columns width to fit better */
    background: #eee;
}

.mainColl div,
.mainColl input {
    padding: 20px;
    border: 1px solid #ccc; /* Add border to match other elements */
    box-sizing: border-box; /* Ensure padding doesn't affect overall size */
    text-align: center; /* Center align text */
}

.mainButton {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #fff;
}

.mainButton div:hover {
    opacity: 0.83;
}

.bgGreen {
    background: green;
}

.bgRed {
    background: red;
}

.pd10 {
    padding: 10px;
}

.shadow:hover {
    box-shadow: 1px 1px 1px 1px black;
    cursor: pointer;
}

.overlay {
    z-index: 3; /* подложка должна быть выше слоев элементов сайта, но ниже слоя модального окна */
    position: fixed; /* всегда перекрывает весь сайт */
    background-color: #000; /* черная */
    opacity: 0.8; /* но немного прозрачна */
    width: 100%;
    height: 100%; /* размером во весь экран */
    top: 0;
    left: 0; /* сверху и слева 0, обязательные свойства! */
    cursor: pointer;
}
