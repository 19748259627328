.main {
    display: grid;
    grid-template-columns: 1fr 2fr; /* First column takes 2/6, second takes 4/6 */
    font-size: 18px;
    grid-gap: 10px;
}
.header{
    font-weight: bold;
    text-align: center;
    padding: 5px;
    border: 1px solid #eee;
    font-size: 18px;
}
.main div{
    padding: 5px;
}
.mainBorder div{
    border: 1px solid #eee;
}
.logo-container {
    display: flex;
    align-items: end;
    justify-content: space-between;
    text-decoration: none;
}

.fa-icon {
    margin-right: 10px;
}

.logo {
    max-height: 20px;
    max-width: 100px;
}
.main a{
    color: black;
    text-decoration: underline;
}
.top{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-column-gap: 10px;
}
.top div{
    background: #eee;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
.top div:hover{
    background: #d2d2d2;
}
.weight{
    font-weight: bold;
}
.blockTwo{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 10px;
}

.partnerLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.partnerLabel a {
    color: black;
    text-decoration: underline;
}

.partnerLabel button {
    color: red;
    background: none;
    border: none;
    cursor: pointer;
    /* Other button styles as needed */
}

/* Styling for tab buttons */
.tabContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.tabButton {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tabButton:hover {
    background-color: #ddd;
}

.tabButton.active {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
}

/* Styling for tab content */
.tabContent {
    padding: 20px;
    border: 1px solid #ddd;
}

/* Style for the table */
.divTable{
    display: table;
    width: 100%;
}
.divTableRow {
    display: table-row;
}
/*
30% -> 60% fields
 */
.divTableCell30 {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
    width: 30%;
}
.divTableCell20 {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
    width: 20%;
}
.divTableCell60 {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
    width: 60%;
}

.divTableCell1 {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
    width: 10%;
}


.divTableCell2 {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
    width: 80%;
}
.divTableCell3 {
    border: 1px solid #999999;
    display: table-cell;
    padding: 3px 10px;
    width: 10%;
}
.divTableBody {
    display: table-row-group;
}
.divTableBody {
    display: table-row-group;
}

.disableBorder {
    border: none !important;
}
