.main{
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 1fr;
}
.main:hover{
    background-color: #eee;
}
.main div{
    padding: 10px;
    border-bottom: 1px solid #eee;
}
.mainAdd{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.font{
    font-weight: bold;
}
.center{
    text-align: center;
}
.mainCash input, select, textarea{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
}
.mainCash select, .mainCash .date{
    width: 90%;
}
.button{
    width: 50%;
    margin-top: 9%;
    margin-left: 10px;
    border: 0;
    padding: 20px;
    text-transform: uppercase;
    font-weight: bold;
}
.mainCash textarea{
    text-align: left;
}
.button:hover{
    background-color: #d2d2d2;
    cursor: pointer;
}
.green{
    background: #0de40d
}