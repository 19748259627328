.main{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
.main:hover{
    background: #eee;
}
.weight{
    font-weight: bold;
    font-size: 18px;
    background: #eee;
    padding: 5px;
}
.main div{
    border-bottom: 1px solid #eee;
    padding: 5px;
}
.green{
    color: green;
}
.red{
    color: red;
}
.border{
    border: 1px solid #eee;
}