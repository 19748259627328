.main, .mainFour{
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 20px;
    text-align: center;
}
.main{
    grid-template-columns: 50px 1fr 1fr 1fr 1fr;
}
.mainFour{
    grid-template-columns: 50px 1fr 1fr 1fr;
}
.main div, .mainFour div{
    border-bottom: 2px solid #eee;
    margin-bottom: 5px;
}
.header{
    font-size: 18px;
    font-weight: bold;
}
.textCenter{
    text-align: center;
}
.unshow{
    display: none;
}
.area{
    background-color: #18283a;
}