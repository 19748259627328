.main{
    position: fixed;
    bottom: 0;
    right: 0;
    padding-bottom: 15px;
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    background: #eee;
}
.list_h3{
    padding: 10px 50px 10px 10px;
    font-weight: bold;
    background: #d2d2d2;
}
.line{
    margin: 5px 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: 30px;
}
.coll{
    text-align: center;
}
.listState{
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    padding: 0 5px;
    border: 1px solid #eee;
    background: #eee;
}
.saveList button{
    float: right;
    margin-right: 15px;
    padding: 10px 20px;
    text-align: center;
    background: #00ba09;
    border: 0;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}
.saveList button:hover{
    background: #0ea315;
}