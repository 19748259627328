.main{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.mainMin{
    border: 1px solid #d2d2d2;
    padding: 5px;
}
.item{
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    margin-bottom: 5px;
}
.item div{
    padding: 2px;
}
.sum{
    padding: 5px;
    font-weight: bold;
    color: rgb(30, 109, 30);
    font-size: 18px;
}
.search{
    padding-bottom: 10px;
}
.search input{
    padding: 5px;
    width: 50%;
    border: 1px solid #d2d2d2;
}
