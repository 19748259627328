.main{
    display: grid;
    grid-template-columns: 4fr repeat(5,2fr) 1fr;
    grid-column-gap: 10px;
    padding: 10px;
}
.mainFilter{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-column-gap: 10px;
    padding: 10px;
}
.mainFilter div{
    background: #eee;
    padding: 10px;
    margin-bottom: 5px;
    text-align: center;
}
.mainFilter div:hover{
    background: rgb(201, 199, 199);
    cursor: pointer;
}
.main:hover{
    background: #d2d2d2;
}
.header{
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}
.info{
    background: #d2d2d2;
    padding: 10px;
    font-size: 18px;
    box-shadow: 1px 1px 1px #eee;
}
.header span{
    padding: 0 7px;
    background: #00ba09;
    color: #fff;
    border-radius: 25px;
}
.header span:hover{
    background: #f90;
    cursor: pointer;
}
.main button{
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
}
.main button:hover{
    background-color: #f90;
}
.greenText{
    color: #00ba09;
    font-weight: bold;
    cursor: pointer;
}
.comment{
    background: #efefef;
    border-left: 5px solid #44912c;
    margin: 5px 0 5px 10px;
    padding: 10px;
    font-size: 20px;
}
.comment svg{
    margin-right: 5px;
}
.date{
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #888;
}
.fs1_5rem{
    font-size: 1.5rem;
}
.blocksGrid{
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
}
.m0{
    margin: 0;
    margin-top: 5px;
    padding: 0;
}
.commentCount{
    font-size:0.5em; 
    vertical-align:bottom;
    font-weight: bold;
    margin-left: 3px;
}
.archive{
    text-align: right;
    margin-right: 10px;
}
.archive a{
    background: #eee;
    padding: 10px;
    color: #00ba09;
}