.main{
    display: grid;
    grid-template-columns: 70px repeat(2, 1fr) 4fr;
    border-bottom: 0 !important;
    border-left: 0 !important;
    font-size: 19px;
}
.main div{
    text-align: center;
    padding: 10px 10px;
    border-bottom: 1px solid rgb(189, 189, 189);
    border-left: 1px solid #d2d2d2;
    box-shadow: 1px 1px 1px #d2d2d2;
}
.miniBlocks{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.miniBlocks div{
    height: 20px;
}
.miniBlocks div:hover{
    cursor: pointer;
    background: #d2d2d2;
}
.miniBlocks div:hover svg{
    color: #44912c;
}
.deffect{
    border-bottom: 0!important;
    width: 95%;
    text-align: left;
    padding: 5px;
    min-height: 75px;
}
.countModuls{
    padding: 2px 5px;
    color: #fff;
    background: red;
    border-radius: 5px;
    font-weight: bold;
}
[data-tooltip] {
    position: relative; /* Относительное позиционирование */ 
}
[data-tooltip]::after {
    content: attr(data-tooltip); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    width: 70px; /* Ширина подсказки */
    left: 0; top: 0; /* Положение подсказки */
    background: #f90; /* Синий цвет фона */
    color: #fff; /* Цвет текста */
    padding: 0.5em; /* Поля вокруг текста */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
    pointer-events: none; /* Подсказка */
    opacity: 0; /* Подсказка невидима */
    transition: 1s; /* Время появления подсказки */
    z-index: 90;
    text-align: center;
} 
[data-tooltip]:hover::after {
    opacity: 1; /* Показываем подсказку */
    top: 2.2em; /* Положение подсказки */
}
