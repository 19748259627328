.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0,0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: start;
    justify-content: top;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    overflow-y: auto;
}
.modal.active{
    opacity: 1;
    pointer-events: all;
    z-index: 100;
}
.modal__content{
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    scale: 0.5;
    transition: 0.4s all;
    overflow: auto;
}
.modal__content.active{
    scale: 1;
}
.close{
    text-align: right;
}
.close span{
    color: #fff;
    cursor: pointer;
    background: #eee;
    padding: 3px 5px;
    font-weight: bold;
    box-shadow: 1px 1px 1px #d2d2d2;
}
.close span:hover{
    color: rgb(212, 41, 41);
    background: #d2d2d2;
}
@media (max-width: 980px){
    .modal__content.active{
        scale: 0.7;
    }
}
