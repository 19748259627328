
.main{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 5px;
    font-size: 17px;
}
.main div{
    /* border-bottom: 1px solid #eee; */
    background: #eee;
    padding: 5px;
    border-left: 1px solid #d2d2d2;
}
.main:hover{
    background: #eee;
}
.hei{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
}