.spanPlus{
    margin-left: 5px;
    color: green;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}
.messages{
    background: #efefef;
    border-left: 5px solid #44912c;
    margin: 5px 0 5px 10px;
    padding: 10px;
    font-size: 20px;
}
.messages svg{
    margin-right: 5px;
}
.date{
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #888;
}
.nameAgent{
    border-bottom: 1px solid #44912c;
}
.nameAgent:hover{
    color: #44912c;
    cursor: pointer;
}