.button{
  text-align: center;
}
.error{
  color: red;
  text-align: center;
}
.button button{
  background: #eee;
  border: 1px solid #eee;
  padding: 10px;
  margin: 1px;
  cursor: pointer;
}
.button button:hover{
  background: #d2d2d2;
}
.formAddComponent{
  text-align: center;
}
.formAddComponent input{
  width: 50%;
  border: 2px solid #d2d2d2;
  padding: 5px;
  margin-bottom: 5px;
}
.right{
  text-align: right;
}
