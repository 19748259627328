.twoBlocksGrid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.mainCheck a, a{
    text-decoration: none;
}
.mainCheck{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.mainCheckDiv{
    margin: 10px;
    color: black;
    background: #eee;
    padding: 15px 5px;
    border-radius: 10px;
    min-height: 130px;
}
.mainCheckDiv a{
    color: #000;
}
.mainCheckDiv div{
    margin: 2px 0;
}
.mainCheckDiv:hover{
    background: #b2b2b2;
}
.mainCheckDiv h3{
    margin-top: 0;
}
.checkDate{
    text-align: right;
    font-size: 12px;
}
.checkDate:hover, .mainCheckDiv a:hover, .left a:hover, .iconDelete:hover{
    cursor: pointer;
    color: #eee;
}
.checkDate svg{
    margin-right: 5px;
}
.red{
    color: red;
}
.left{
    text-align: left;
    padding-left: 5px;
    
}
.modal{
    text-align: center;
}
.modal input{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    width: 80%;
}
.modal button{
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
    margin-top: 10px;
}
.iconDelete{
    margin-left: 10px;
    cursor: pointer;
}