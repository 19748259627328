.main{
    display: grid;
    grid-template-columns: 100px 2fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    margin-top: 20px;
    background: #eee;
    padding: 10px;
    border: 1px solid #d2d2d2;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.add button{
    float: right;
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
}
.add button:hover{
    background-color: #36ab3c;
}
.addForAkon{
    grid-template-columns: 100px repeat(3,1fr);
}
.addForAkon input{
    padding: 5px;
    border: 2px solid #eee;
    margin-right: 2px;
}
.addForAkon button{
    border: 2px solid #d2d2d2;
    padding: 5px;
    background: #eee;
    cursor: pointer;
    bottom: 5px;
}
.addForAkon button:hover{
    background: #d2d2d2;
}