.title{
    border-bottom: 1px solid #ff9900;
}
.moneyDelivery{
    text-align: right;
    margin-right: 20px;
    margin-top: 10px;
}
.moneyDelivery span{
    padding: 5px;
    background: #eee;
}
.green{
    color: green;
}
.red{
    color: red;
}
.main{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    margin-bottom: 5px;
  }
  .main div{
    padding: 10px;
    background: #eee;
    margin: 0 5px;
    text-align: center;
  }
  .main div:hover{
    background: #d2d2d2;
    cursor: pointer;
  }
  .main a{
    color: #000;
  }
  .active{
    background: #d2d2d2 !important;
  }