.center{
    text-align: center;
}
.filtr{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin: 0px 0;
}
.textarea{
    border-bottom: 0!important;
    width: 95%;
    text-align: left;
    padding: 5px;
    min-height: 75px;
}
.filtr div{
    margin: 2px 2px;
    background: #eee;
    padding: 0px;
    text-align: center;
    cursor: pointer;
}
.filtr div:hover, .button:hover{
    background: #d2d2d2;
}
.button{
    padding: 10px;
    background: #eee;
    width: 200px;
    text-align: center;
    cursor: pointer;
    border: 0;
}
.input{
    margin-bottom: 10px;
    padding: 5px 10px;
}
.green{
    color: #00ba09;
}
.red{
    color: red;
}
/* Новые классы */
.firstBlock{
    display: grid;
    grid-template-columns: 7fr 1fr 1fr 1fr;
}
.greenNew, .redNew, .yellowNew{
    padding: 4px;
    max-width: 60px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    height: 19px;
    opacity: 0.4;
}
.greenNew:hover, .redNew:hover, .yellowNew:hover{
    opacity: 1;
    cursor: pointer;
    color: #d2d2d2;
}
.greenNew{
    background: #00ba09;
    display: none;
}
.yellowNew{
    background: #ffb700;
    display: none;
}

.redNew{
    background: red;
    display: none;
}
.filtrBlockNew:hover{
    cursor: pointer;
}
.filtrBlockNew:hover .greenNew{
    display: block;
}
.filtrBlockNew:hover .yellowNew{
    display: block;
}
.filtrBlockNew:hover .redNew{
    display: block;
}
.dontShow{
    display: none;
}
.link{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.mainSmallBlock:hover .redNewSmallBlock{
    opacity: 0.4;
}
.smallBlock{
    margin: 10px;
    font-size: 16px;
    background: #4f81bd;
    padding: 30px 5px;
    border-radius: 10px;
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    width: 90%;
    cursor: pointer;
}
.smallBlock:hover, .smallBlockBg:hover{
    opacity: 0.7;
}
.link a{
    text-decoration: none;
    color: #fff;
}
.redNewSmallBlock{
    opacity: 0;
    color: red;
    text-align: right;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14px;
}
.redNewSmallBlock span{
    padding: 5px;
    margin: 0 2px;
    background: #fff;
    border-radius: 5px;
    opacity: 0.4;
}
.redNewSmallBlock span:hover{
    opacity: 1 !important;
    cursor: pointer;
}
.text{
    padding: 0 !important;
    margin: 0 !important;
}
.linkBg{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}
.smallBlockBg{
    margin: 10px;
    font-size: 16px;
    background: #4f81bd;
    padding: 20px;
    border-radius: 10px;
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    cursor: pointer;
}
.twoBlocksWithH1{
    display: grid;
    grid-template-columns: 1fr 10fr;
}
.twoBlocksWithH1 h1{
    margin: 10px 0 0 0;
}
.number{
    position: relative;
    bottom: 15px;
    left: 10px;
    background: #f70000;
    padding: 2px 7px;
    font-weight: bold;
    border-radius: 12px;
}
.course{
    position: absolute;
    right: 0;
    top: 5%;
    padding: 10px;
    margin-right: 5px;
}
.course div{
    font-weight: bold;
    padding: 5px;
}
.blueBg{
    background: #00f;
    color: #fff;
}
.yellowBg{
    background: yellow;
    color: #000;
}
