.main{
    display: grid;
    grid-template-columns: repeat(5,1fr);
}
.active:hover{
    cursor: pointer;
    opacity: 0.7;
}
.red{
    color: red;
}