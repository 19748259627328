.mainSpec{
    display: grid;
    grid-template-columns: 8fr 4fr;
    height: 1px;
}
.main{
    display: grid;
    grid-template-columns: 30px repeat(4,1fr);
    grid-auto-flow: dense;
    margin-top: 5px;
    font-size: 18px;
    text-align: center;
    padding: 5px 0;
}
.main:hover{
    background: #eee;
}
.mainThreeHeader{
    font-weight: bold;
}
.inputColl{
    margin: 10px;
}
.inputColl input, .inputColl button{
    padding: 10px;
    text-align: center;
    border: 1px solid #d2d2d2;
    font-weight: bold;
    margin-bottom: 20px;
}
.inputColl button{
    cursor: pointer;
}
.description{
    text-align: left;
    margin: 20px 0;
}
.name{
    margin: 20px 0;
    font-size: 24px;
}
.weight{
    font-weight: bold;
    
}
.leftBlock{
    border-top: 2px dashed #eee;
    border-left: 2px dashed  #eee;
    padding: 10px;
    
}
.saveBlock{
    text-align: center;
    padding: 10px 0;
    border: 1px solid #d2d2d2;
    width: 90%;
    color: #888;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5px;
    margin-bot: 5px;
}
.saveBlock:hover{
    background: rgb(218, 133, 5);
    color: white;
}
.header{
    padding: 10px;
    background: #eee;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.header input{
    height: 100%;
    width: 50%;
    border: 1px solid #eee;
    text-align: center;
    font-size: 20px;
}
.header button{
    height: 100%;
    background: #d2d2d2;
    text-align: center;
    font-size: 20px;
    border: 1px solid #eee;
    cursor: pointer;
}
.header button:hover{
    background: #888;
}
.green{
    color: green;
}
.red{
    color: red;
}
.delete{
    color: red;
    cursor: pointer;
}
.delete:hover{
    padding: 5px;
    background: #f90;
    font-weight: bold;
    color: white;
}
.collElem{
    width: 50px;
    height: 25px;
    border: 1px solid #d2d2d2;
    text-align: center;
    padding-left: 10px;
}
.active{
    background: #f90;
    color: white;
}
.disabled{
    background: #3d4263;
    color: white;
}
.disabled:hover{
    background: #3d4263 !important;
    color: white !important;
}

.version{
    padding: 5px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    font-weight: 500;
}
.version:hover{
    background: #eee;
    color: #f90;
}
.pb_20{
    padding-bottom: 20px;
}