.main{
    display: grid;
    grid-template-columns: 50px repeat(4,1fr);
    text-align: center;
    background: #eee; 
}
.main div{
    text-align: center;
    padding: 10px 10px;
    border-bottom: 1px solid rgb(189, 189, 189);
    border-left: 1px solid #d2d2d2;
    box-shadow: 1px 1px 1px #d2d2d2;
}
.mainSection{
    margin-top: 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #d2d2d2;
    box-shadow: 2px 2px 2px #888;
}
.mainTwoBlocks{
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-bottom: 0 !important;
    border-left: 0 !important;
}
.red{
    color: red;
}
.icon{
    margin-left: 10px;
    color: #888;
    cursor: pointer;
}
.icon:hover{
    color:#ff9900;
}
.changeDate{
    text-align: center;
}
.changeDate input{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    width: 80%;
}
.changeDate button{
    margin-right: 10px;
    border: none;
    padding: 10px 15px;
    color: #fff;
    background-color: #00ba09;
    cursor: pointer;
    margin-top: 10px;
}
.active div:hover{
    background: #d2d2d2;
    cursor: pointer;
}

